import './chart.scss';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, AreaChart } from 'recharts';

const data = [
    { name: 'Jan', total: 7.50, cdi: 5.5 },
    { name: 'Fev', total: 8.32, cdi: 5.9 },
    { name: 'Mar', total: 6.45, cdi: 6.2 },
    { name: 'Abr', total: 5.98, cdi: 6.5 },
    { name: 'Maio', total: 7.30, cdi: 7.0 },
    { name: 'Jun', total: 9.42, cdi: 7.3 },
    { name: 'Jul', total: 9.52, cdi: 7.7 },
    { name: 'Ago', total: 10.11, cdi: 8.0 },
    { name: 'Set', total: 9.97, cdi: 8.5 },
    { name: 'Out', total: 10.47, cdi: 8.9 },
    { name: 'Nov', total: 11.22, cdi: 9.5 },
    { name: 'Dez', total: 11.86, cdi: 9.9 },
  ];

const Chart = () => {
    return (
        <div className="chart">
            <div className="title">Rentabilidade</div>
            <ResponsiveContainer width="100%" aspect={2 / 1}>
                <AreaChart
                    width={730}
                    height={250}
                    data={data}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area 
                    type="monotone"
                    dataKey="total"
                    stroke="#8884d8"
                    fillOpacity={1}
                    fill="url(#total)" 
                    />
                <Area 
                    type="monotone"
                    dataKey="cdi"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="url(#cdi)" 
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}
export default Chart;