import { CircularProgressbar } from 'react-circular-progressbar';
import './featured.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import "react-circular-progressbar/dist/styles.css";

const Featured = () => {
    return (
        <div className="featured">
            <div className="top">
                <h1 className="title">Meta de Proventos Mensais</h1>
                <MoreVertIcon fontSize='small'/>
            </div>
            <div className="bottom">
                <div className="featuerdChart">
                    <CircularProgressbar 
                        value={73.4}
                        text={"73,4%"}
                        strokeWidth={5}/>
                </div>
                <p className="title">Este mês</p>
                <p className="amount">R$367,15</p>
                <p className="desc">
                    Comparativo dos proventos recebidos esse mês em relação ao ultimo mês.
                </p>

                <div className="summary">

                <div className="item">
                        <div className="itemTitle">Meta</div>
                        <div className="itemResult negative">
                            <KeyboardArrowDownIcon fontSize='small'/>
                            <div className="resultAmount">R$500,00</div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="itemTitle">Ultimo mês</div>
                        <div className="itemResult positive">
                            <KeyboardArrowUpOutlinedIcon fontSize='small'/>
                            <div className="resultAmount">R$328,49</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Featured;