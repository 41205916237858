import React from 'react';
import './new.scss'

const New = () => {
    return (
      <div>new</div>
    );
  }
  
  export default New;
  