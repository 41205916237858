import React from 'react';
import './single.scss'

const Single = () => {
    return (
      <div>single</div>
    );
  }
  
  export default Single;
  