import React from 'react';
import './login.scss'

const Login = () => {
    return (
      <div>login</div>
    );
  }
  
  export default Login;
  