import './widget.scss'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';

const Widget = ({ type }) => {
    let data;

    let amount = 120;
    let percentage = 20;
    switch (type) {
        case 'balance':
            data = {
                title: 'Patrimonio',
                isMoney: true,
                counter: 27524.4,
                link: "Visualizar",
                icon: (
                    <AccountBalanceWalletOutlinedIcon
                        className='icon' 
                        style={{
                            color: 'purple', 
                            backgroundColor: "rgba(128, 0, 128, 0.2)",
                        }}
                    /> 
                )
            }
            break;
        case 'user':
            data = {
                title: 'Próxima Meta',
                isMoney: true,
                counter: 30000,
                link: "Visualizar metas",
                icon: (
                    <PersonOutlinedIcon 
                        className='icon' 
                        style={{
                            color: 'crimson', 
                            backgroundColor: "rgba(255, 0, 0, 0.2)",
                        }}
                    /> 
                )
            }
        break;
        case 'order':
            data = {
                title: 'Rentabilidade (12 meses)',
                isMoney: false,
                counter: '11.86%',
                link: "Visualizar tudo",
                icon: (
                    <ShoppingCartOutlinedIcon
                        className='icon' 
                        style={{
                            color: 'goldenrod', 
                            backgroundColor: "rgba(218, 165, 32, 0.2)",
                        }}
                    /> 
                )
            }
            break;
        case 'earning':
            data = {
                title: 'Proventos (12 meses)',
                isMoney: true,
                counter: 1389,
                link: "Visualizar todos",
                icon: (
                    <MonetizationOnOutlinedIcon
                        className='icon' 
                        style={{
                            color: 'green', 
                            backgroundColor: "rgba(0, 128, 0, 0.2)",
                        }}
                    /> 
                )
            }
        break;
        
        default: 
            break;
        ;
    }

    return (
        <div className="widget">
            <div className="left">
                <span className="title">{data.title}</span>
                <span className="counter">{data.isMoney && 'R$'} {data.counter}</span>
                <span className="link">{data.link}</span>
            </div>
            <div className="right">
                <div className="percentage positive">
                    <ArrowOutwardOutlinedIcon/>
                    {percentage} %
                </div>
                {data.icon}
            </div>
        </div>
    )

} 
export default Widget;