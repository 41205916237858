import React, { useEffect, useState } from 'react';
import './home.scss'
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import Widget from '../../components/widget/Widget';
import Featured from '../../components/featured/Featured';
import Chart from '../../components/chart/Chart';
import { Avatar, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';



function createData(icon, name, price, target, efficient, risk, decision) {
  return { icon, name, price, target, efficient, risk, decision };
}

const rows = [
  createData("331", "BBAS3", "R$ 58.61", "R$ 61.00", "Muito Eficiente", "Moderado", "Neutro"),
  createData("462", "CPLE3", "R$ 13.52", "R$ 12.04", "Não Eficiente", "Moderado", "Neutro"),
  createData("343", "SANB3", "R$ 13.38", "R$ 14.17", "Não Eficiente", "Moderado", "Neutro"),
  createData("344", "ITUB3", "R$ 29.04", "R$ 36.11", "Eficiente", "Baixo", "Compra"),
  createData("215", "MGLU3", "R$ 1.55", "R$ 1.70", "Não Eficiente", "Alto", "Venda"),
  createData("399", "GOAU3", "R$ 10.32", "R$ 11.33", "Eficiente", "Moderado", "Neutro"),
  createData("455", "CMIG3", "R$ 13.63", "R$ 14.01", "Eficiente", "Moderado", "Compra")

];


const Home = () => {

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch('https://jsonplaceholder.typicode.com/posts?_limit=10')
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setPosts(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div className="home">
      {/* <Navbar/> */}
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget type="balance" />
          <Widget type="user" />
          <Widget type="order" />
          <Widget type="earning" />
        </div>
        <div className="charts">
          <Featured />
          <Chart />
        </div>

        <div className="charts">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>Ações</TableCell> */}
                  <TableCell align="center">Icone</TableCell>
                  <TableCell align="center">Nome</TableCell>
                  <TableCell align="right">Preço</TableCell>
                  <TableCell align="right">Previsão</TableCell>
                  <TableCell align="right">Eficiencia</TableCell>
                  <TableCell align="right">Risco</TableCell>
                  <TableCell align="right">Decisão</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {/* <TableCell component="th" scope="row">
                        {row.icon}
                      </TableCell> */}
                    <TableCell align="center">
                      {/* <img src={`https://statusinvest.com.br/img/company/avatar/${row.icon}.jpg?v=8`} alt="nome"/> */}
                      <Avatar variant="square" srcSet={`https://statusinvest.com.br/img/company/avatar/${row.icon}.jpg?v=8`}>
                      </Avatar>
                    </TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="right" className='bold'>{row.price}</TableCell>
                    <TableCell align="right">{row.target}</TableCell>
                    <TableCell align="right">{row.efficient}</TableCell>
                    <TableCell align="right">{row.risk}</TableCell>
                    <TableCell align="right">{row.decision}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div className="charts">
          <div className="posts-container">
            {posts.map((post) => {
              return (
                <div className="post-card" key={post.id}>
                  <h2 className="post-title">{post.title}</h2>
                  <p className="post-body">{post.body}</p>
                  <div className="button">
                    <div className="delete-btn">Delete</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
