import React from 'react';
import './sidebar.scss'
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';



const Sidebar = () => {
    return (
        <div className="sidebar">
            <div className="top"><span className="logo">Inteligente</span></div>
            <hr />    
            <div className="center">
                <ul>
                    <p className="title">MAIN</p>
                    <li>
                        <DashboardIcon className='icon'/>
                        <span className="">Dashboard</span>
                    </li>
                    <p className="title">Investidor</p>
                    <li>
                        <PeopleAltOutlinedIcon className='icon'/>
                        <span className="">Patrimônio</span>
                        </li>
                    <li><span className="">Ações</span></li>
                    <li><span className="">Compras</span></li>
                    <li><span className="">Proventos</span></li>
                    <p className="title">Gerenciar</p>
                    <li><span className="">Estatísticas</span></li>
                    <li><span className="">Recomendações</span></li>
                    <li><span className="">Configurações</span></li>
                    
                    <p className="title">Usuário</p>
                    <li>
                    <PersonOutlineOutlinedIcon className='icon'/>
                        <span className="">Conta</span></li>
                    <li>
                        <ExitToAppOutlinedIcon className='icon'/>
                        <span className="">Sair</span></li>
                </ul>
            </div>   
            <div className="bottom">
                <div className="colorOption"></div>
                <div className="colorOption"></div>
            </div>   
        </div>
    );
  }
  
  export default Sidebar;
  